import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DiamondDivider from "../components/diamond-divider"
import EntryButton from "../components/entry-button"
import Bio from "../components/bio"

class BlogIndex extends React.Component {
  constructor(props) {
    super(props);
    const { data, location } = props;
    this.data = data;
    this.location = location;

    this.siteTitle = data.site.siteMetadata?.title || `Title`
    this.posts = data.allMarkdownRemark.nodes;
  }

  render() {
    if (this.posts.length === 0) {
      return (
        <Layout>
          <SEO title="Home" />
          <center>Nothing to say at the moment.</center>
          <DiamondDivider />
          <Bio />
        </Layout>
      )
    }
  
    return (
      <Layout location={this.location} title={this.siteTitle}>
        <SEO title="Home" />
        {this.posts.map((post, index) => {
            const title = post.frontmatter.title || post.fields.slug;
            let classNames;
            if (index === this.posts.length - 1) {
              classNames = 'last-divider';
            }
            return (
              <div
                key={title}
                className="post"
                itemScope
                itemType="http://schema.org/Article"
              >
                <EntryButton post={post} />
                <DiamondDivider className={classNames} />
              </div>
            )
          })}
          <Bio />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
